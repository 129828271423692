<template>
  <div>
    <!-- 主体 -->
    <LiefengContent >
      <template v-slot:title>平台组件管理</template>
      <!-- 头部搜索层 -->
      <template v-slot:toolsbarRight>
          <span slot="label" class="validate" style="margin-top: 5px;margin-right: 5px">功能上级:</span>
          <Cascader
            style="margin-right: 10px"
            v-model="searchForm.functionId"
            :data="childrenList"
            change-on-select
          /> 
          <Input v-model.trim="searchForm.keyword" placeholder="请输入功能名称/编码" style="width: 160px;margin-right:10px"></Input>
          <Button type="primary" icon="ios-search" @click="searchBtn" style="margin-right: 10px">查询</Button>
          <Button type="success" @click="resetBtn" icon="ios-refresh" style="margin-right: 10px">重置</Button>
          <Button
              icon="ios-add"
              type="primary"
              @click="addForm"
          >新增
          </Button>
      </template>
      <!-- 分页层 -->
      <template v-slot:contentArea>
        <!-- 分页table -->
        <LiefengTable
            :talbeColumns="pageForm.talbeColumns"
            :tableData="pageForm.tableData"
            :loading="controlForm.loading"
            :fixTable="true"
            :curPage="pageForm.page"
            :total="pageForm.total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageForm.pageSize"
            @hadlePageSize="changeTableSize"
        ></LiefengTable>
        <FormAdd ref="FormAdd" @resetBtn="searchBtn" @getAllFunction="getChildrenList"></FormAdd>
        <FormEdit ref="FormEdit" @resetBtn="searchBtn" @getAllFunction="getChildrenList"></FormEdit>

        <LiefengModal
          :title="`${fnName} 组件已关联一下${contextNum}个栏目`"
          :value="fnStatus"
          @input="changeFnStatus"
          :fullscreen="false"
          width="500px"
          height="500px"
          >
          <template v-slot:contentarea>
            <Tree v-if="contextNum" :data="columnData"></Tree>
            <p v-if="!contextNum" class="nonelist">暂无关联栏目</p>
          </template>
          <template v-slot:toolsbar>
              <Button type="info" @click="changeFnStatus(false)">关闭</Button>
          </template>
        </LiefengModal>
      </template>
      
      

    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import FormAdd from './childrens/add'
import FormEdit from './childrens/edit'

export default {
  components: {LiefengContent, LiefengTable, LiefengModal, FormAdd, FormEdit},
  data() {
    return {
      childrenList: [],
      functionIdTemp: '',
      //分页参数
      pageForm: {
        talbeColumns: [
          {
            title: "平台组件名称",
            key: "functionName",
            minWidth: 200,
            align: "center"
          },
          {
            title: "所属类型",
            key: "functionCode",
            minWidth: 200,
            align: "center"
          },
          {
            title: "webaction",
            key: "urlPc",
            minWidth: 200,
            align: "center"
          },
          {
            title: "H5action",
            key: "urlH5",
            minWidth: 200,
            align: "center"
          },
          {
            title: "小程序action",
            key: "urlWechat",
            minWidth: 200,
            align: "center"
          },
          {
            title: "创建时间",
            key: "gmtCreate",
            minWidth: 200,
            align: "center",
            render:(h,params) => {
                return h('div',{},params.row.gmtCreate ? this.$core.formatDate(
                  new Date(params.row.gmtCreate),
                  "yyyy-MM-dd"
                ):'')
              }
          },
          {
            title: "状态",
            key: "status",
            minWidth: 200,
            align: "center",
             render: (h, params) => {
              return h(
                'i-Switch',
                {
                  props: {
                    size: 'large',
                    value: params.row.enableStatus == 1
                  },
                  scopedSlots: {
                    open: () => h('span', '启用'),
                    close: () => h('span', '停用')
                  },
                  on: {
                    'on-change': () => {
                      this.changeStatus(params.row)
                    }
                  }
                }
              )
            }
            // render:(h,params) =>{
            //   return h('div',{},params.row.status == 1 ?'正常':'禁用' )
            // }
          },
          {
            title: "操作",
            align: "center",
            fixed: "right",
            width: 180,
            render: (h, params) => {
              return h(
                  "div",
                  {
                    style: {
                      textAlign: "center"
                    }
                  },
                  [
                    h(
                        "Dropdown",
                        {
                          props: {
                            transfer: true
                          }
                        },
                        [
                          h(
                              "Button",
                              {
                                props: {
                                  type: "success",
                                  size: "small",
                                  icon: "ios-arrow-down"
                                }
                              },
                              "操作"
                          ),
                          h(
                              "DropdownMenu",
                              {
                                slot: "list"
                              },
                              [
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.getDetail(params.row.functionId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "修改"
                                ),

                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.deleteRow(params.row.functionId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "删除"
                                ),
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.getColumn(params.row)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "已关联栏目"
                                ),
                              ]
                          )
                        ]
                    )
                  ]
              );
            }
          },

        ],
        tableData: [],
        loading: false,
        page: 1,
        pageSize: 20,
        total: 0,
        currentPage: 0
      },
      //控制的一些属性
      controlForm:{
        loading:false,
      },
      //查询参数
      searchForm: {
        keyword: '',
        functionId: []
      },

      // 关联栏目
      fnStatus:false,
      fnName:"",
      contextNum:"",
      // 栏目树
      columnData:[],
    }
  },
  //create函数,刚加载页面时调用
  async created() {
    this.getChildrenList();
    await this.selectPage();
  },
  methods: {
    /*----- 分页事件部分 -------*/

    //分页事件改变
    changeTableSize(val) {
      this.pageForm.page = val.page
      this.pageForm.pageSize = val.pageSize
      this.selectPage()
    },
    //查询按钮
    searchBtn() {
      this.pageForm.page = 1
      this.selectPage()
    },
    //重置按钮
    resetBtn() {
      this.searchForm.keyword = ''
      this.searchForm.functionId = []
      this.pageForm.page = 1
      this.selectPage()
    },


    /*----- modal部分 -------*/
    //新增按钮
    addForm() {
      this.$refs.FormAdd.controlForm.formFlag = true;
    },
    async getFatherFunctionList() {
      this.$refs.FormAdd.controlForm.fatherFunctionList = this.childrenList;
    },
    // 方法部分

    /*----- 接口部分 -------*/

    async selectPage() {
      if (this.searchForm.functionId && this.searchForm.functionId.length) {
        this.functionIdTemp = this.searchForm.functionId[this.searchForm.functionId.length - 1];
      } else {
        this.functionIdTemp = ''
      }
      this.controlForm.loading = true
      await this.$get('/auth/api/auth/pc/systemFunction/selectByPage', {
        page: this.pageForm.page,
        pageSize: this.pageForm.pageSize,
        keyword: this.searchForm.keyword,
        functionId: this.functionIdTemp
      }).then(res => {
        this.controlForm.loading = false
        if (res.code == 200) {
          this.pageForm.tableData = res.dataList
          this.pageForm.total = res.maxCount
          this.pageForm.currentPage = res.currentPage
          this.getFatherFunctionList();
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },
    // 获取详情接口
    getDetail(functionId){
      this.$refs.FormEdit.getSystemDetail(functionId)
      this.$refs.FormEdit.controlForm.formFlag = true
    },
    async deleteRow(functionId) {
      this.$Modal.confirm({
        title: "删除确定",
        content: "您正在对功能进行删除操作、请确保功能下无数据、并且当前操作不可逆转，是否继续？",
        onOk: () => {
          this.$post('/auth/api/auth/pc/systemFunction/deleteByFunctionId',{
            functionId
          }).then(res=>{
            if(res.code == 200){
              this.$Message.success({
                content:'删除成功',
                background:true
              })
              this.resetBtn();
            }else{
              this.$Message.error({
                content:'删除失败',
                background:true
              })
              return
            }
          })
        },
      });
    },

    //递归改children
    dg(children){
      for (var i = 0; i < children.length; i++) {
        children[i]['label'] = children[i].functionName;
        children[i]['value'] = children[i].functionId;
        if (children[i].children) {
          this.dg(children[i].children);
        } else {
          delete(children[i]['children'])
        }
      }
    },

    // 获取全部子级
    getChildrenList(){
      this.$get("/auth/api/auth/pc/systemFunction/getAllFunctionsAndChildren", {
      }).then(res => {
        if (res.code == 200) {
          this.childrenList = res.dataList;
          //处理成label，value
          this.dg(this.childrenList);
        }
      });
    },

    // 更改状态
    changeStatus(data){
      let status = ''
      if(data.enableStatus == 1){
        status = 2
      }else {
        status = 1
      }
      this.$post('/auth/api/auth/pc/systemFunction/updateStatus',{
        functionId:data.functionId,
        enableStatus:status
      }).then( res => {
        if(res.code == 200){
          this.$Message.success({
            content:'操作成功',
            background:true
          })
          this.selectPage()
        }else{
          this.$Message.error({
            content:'操作失败',
            background:true
          })
          this.selectPage()
        }
      })
    },
    // 已关联栏目框
    changeFnStatus(val){
      this.fnStatus = val
    },
    // 获取关联栏目信息
    getColumn(data){
      this.fnStatus = true
      this.fnName = data.functionName
      this.$get('/auth/api/auth/pc/systemColumn/selectColumnTree',{
        functionId:data.functionId
      }).then(res => {
        if(res.code == 200){
          if(res.dataList.length != 0){
            this.contextNum = res.dataList.length
            this.columnData = res.dataList
            this.getColumnTree(this.columnData)
          }else {
            this.contextNum = 0
            this.columnData = []
          }
          
          
        }
      })
    },

    getColumnTree(list){
      list.forEach( (e,i) => {
        list[i] = {
          title : e.columnName,
          expand : true,
          children: e.children
        }
        if (list[i].children) {
          this.getColumnTree(list[i].children);
        } else {
          delete(list[i]['expand'])
          delete(list[i]['children'])
        }
      } )
    },
  }
}
</script>
<style lang="less" scoped>
/deep/ #toolsbarRight {
  width: 100%;

  .search {
    width: 100%;
    text-align: right;

    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}
.nonelist{
  height: 448px;
  line-height: 448px;
  text-align: center;
  color: #666666;
}

// /deep/.ivu-modal-close {
//   display: none;
// }
</style>